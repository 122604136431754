import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import GoToTop from './GoToTop';
//For sticky Navbar
import Headroom from 'react-headroom';
// For link 
import { Link } from 'react-router-dom';

const Home = React.lazy(()=> import('./Components/LandingPage/Home'));
const AboutUs = React.lazy(()=> import('./Components/AboutUs/AboutUs'));
const Blogs = React.lazy(()=> import('./Components/Blogs/Blogs'));
const ContactUs = React.lazy(()=>import('./Components/ContactUs/ContactUs'));
const SingleBlog = React.lazy(()=>import('./Components/Blogs/SingleBlog'));
const Book = React.lazy(()=>import('./Components/Appointment/Book'));
const Whathurts = React.lazy(()=>import('./Components/Whathurts/Whathurts'));
const SingleWhathurts = React.lazy(()=>import('./Components/Whathurts/SingleWhathurts'));
const Treatment = React.lazy(()=>import('./Components/Treatment/Treatment'));
const ManualTherapy = React.lazy(()=>import('./Components/Treatment/ManualTherapy'));
const IDDTherapy = React.lazy(()=>import('./Components/Treatment/IDDTherapy'));
const ShockwaveMTTherapy = React.lazy(()=>import('./Components/Treatment/ShockwaveMTTherapy'));
const Testimonials = React.lazy(()=>import('./Components/Testimonials/Testimonials'));
const Resources = React.lazy(()=>import('./Components/Resources/Resources'));
const PageNotFound = React.lazy(()=>import('./PageNotFound'));

function App() {

  return (
    <Router>   
        <div className="App">
            <Headroom>
            <Navbar />
            </Headroom>
            <div className="contents">
            <React.Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route exact path="/"                 element={<Home/>} />
                <Route exact path="/Book"             element={<Book/>} />
                <Route exact path="/About"            element={<AboutUs/>} />
                <Route exact path="/Blog"             element={<Blogs/>} />
                <Route exact path="/Single_Blog/:id"  element={<SingleBlog/>} />
                <Route exact path="/Whathurts"        element={<Whathurts/>} />
                <Route exact path="/SingleWhathurts"  element={<SingleWhathurts/>} />
                <Route exact path="/Testimonials"     element={<Testimonials/>} />
                <Route exact path="/Treatment"        element={<Treatment/>} />
                <Route exact path="/ManualTherapy"  element={<ManualTherapy/>} />
                <Route exact path="/IDDTherapy"  element={<IDDTherapy/>} />
                <Route exact path="/ShockwaveMTTherapy"  element={<ShockwaveMTTherapy/>} />
                <Route exact path="/Resources"        element={<Resources/>} />
                <Route exact path="/ContactUs"        element={<ContactUs/>} />
                <Route path="*"                       element={<PageNotFound/>} /> 
            </Routes>
            </React.Suspense>
            </div>


          {/** Sidebar */}
          <div className="offcanvas offcanvas-end sideBar" tabIndex="1" id="sidebar" aria-labelledby="sidebar-label">

          <div className="offcanvas-header">
          <Link to="/" className="offcanvas-title fw-bold" id="sidebar-label"> <span className='LightDark_Green'>Kino Physiotherapy </span><span className="text-secondary fw-normal fst-italic "><sub>Heal first...</sub></span></Link>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>


          <div className="offcanvas-body bg-color">
              <div className="d-flex flex-column">
                  <ul className="d-flex flex-column gap-3 list-unstyled align-items-start px-3">
                      <li>
                      <Link to="/" className="text-center" onClick={()=>{ window.scroll(0,0); }}>
                          <div className="text" id="KinoBtn" data-bs-dismiss="offcanvas">Home</div>
                      </Link>
                      </li>
                      <li >
                      <Link to="/About" className=" text-center" onClick={()=>{ window.scroll(0,0); }}>
                          <div className=" text" id="KinoBtn" data-bs-dismiss="offcanvas">About us</div>
                      </Link>
                      </li>
                      <li>
                      <Link to="/Blog" className=" text-center" onClick={()=>{ window.scroll(0,0); }}>
                          <div className="text"  id="KinoBtn" data-bs-dismiss="offcanvas">Blogs</div>
                      </Link>
                      </li>
                      <li >
                      <Link to="/Whathurts" className=" text-center " onClick={()=>{ window.scroll(0,0); }}>
                          
                          <div className=" text" id="KinoBtn" data-bs-dismiss="offcanvas">What hurts?</div>
                      </Link>
                      </li>
                      <li >
                      <Link to="/Treatment" className=" text-center " onClick={()=>{ window.scroll(0,0); }}>
                          
                          <div className=" text" id="KinoBtn" data-bs-dismiss="offcanvas">Treatments</div>
                      </Link>
                      </li>
                      <li >
                      <Link to="/Testimonials" className=" text-center " onClick={()=>{ window.scroll(0,0); }}>
                          
                          <div className=" text" id="KinoBtn" data-bs-dismiss="offcanvas">Testimonials</div>
                      </Link>
                      </li>
                      <li >
                      <Link to="/Resources" className=" text-center " onClick={()=>{ window.scroll(0,0); }}>
                          
                          <div className=" text" id="KinoBtn" data-bs-dismiss="offcanvas">Resources</div>
                      </Link>
                      </li>

                      <li >
                      <Link to="/ContactUs" className=" text-center " onClick={()=>{ window.scroll(0,0); }}>
                          <div className=" text" id="KinoBtn" data-bs-dismiss="offcanvas">Contact us</div>
                      </Link>
                      </li>
                  </ul>
              </div>
          </div>
          </div>


        <Footer />
    </div>
    <GoToTop />
    </Router>
  );
}

export default App;
