import "../assets/css/index.css";
import KinoLogo from '../Resources/Images/FooterKL.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return ( 
    <footer>
        <section className="pt-5" id="Footer">
            <div className="container border-bottom px-5">
                <div className="row row-cols-1 row-cols-lg-2">
                    <div className="col col-lg-4" data-aos="fade-up">
                        <div className="d-flex flex-column gap-3 text-white">
                            <div className="text-center">
                                <img src={KinoLogo} alt="" className="img-fluid"/> <h6 className='font-1'>KINO PHYSIOTHERAPY</h6>
                            </div>
                            <p className="text-justify">
                            Our team of experienced physiotherapists is dedicated to helping you regain your mobility and enhance your overall well-being.
                            </p>
                            <div className="social-container text-center mb-3">
                                <a href="https://www.facebook.com/profile.php?id=100072206267565" target="_blank" data-aos="fade-up" data-aos-delay="100">
                                    <i className="fa-brands fa-facebook"></i>
                                </a>
                                <a href="https://x.com/kinophysio96" target="_blank" data-aos="fade-up" data-aos-delay="300">
                                    <i className="fa-brands fa-twitter"></i>
                                </a>
                                <a href="https://www.youtube.com/channel/UCWyZkqNQeBUiqU6_f_1hd5A" target="_blank" data-aos="fade-up" data-aos-delay="500">
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                                <a href="https://www.instagram.com/kinophysiotherapy/" target="_blank" data-aos="fade-up" data-aos-delay="700">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-8">
                        <div className="row row-cols-1 row-cols-lg-3 text-white SmallFont">
                            <div className="col" data-aos="fade-up" data-aos-delay="150">
                                <h5 className="font-2 mb-3 fs-5 pt-3 pt-md-0 pt-lg-0">Quick Links</h5>
                                <ul className="list gap-2 text fw-light">
                                    <li>
                                        <Link to="/" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/About" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Blog" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Blogs
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            What hurts?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Treatment" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Treatments
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ContactUs" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col" data-aos="fade-up" data-aos-delay="300">
                                <h5 className="font-2 mb-3 fs-5 pt-3 pt-md-0 pt-lg-0">Services</h5>
                                <ul className="list gap-2 fw-light">
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            IDD Therapy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Shockwave Therapy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Neuro Rehabilitation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Exercise Therapy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Sports Injury Rehabilitation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Whathurts" className="d-flex flex-row gap-3 align-items-center link">
                                            <i className="fa-regular fa-circle-dot"></i>
                                            Joint Pain Managment
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col mb-3 mb-md-0 mb-lg-0" data-aos="fade-up" data-aos-delay="450">
                                <h5 className="font-2 mb-3 fs-5 pt-3 pt-md-0 pt-lg-0">Information</h5>
                                <div className="d-flex flex-column gap-3 fw-light">
                                    <div className="d-flex flex-row align-items-center gap-3 text-white">
                                        <span className="fs-2"><i className="fa-solid fa-phone"></i></span>
                                        <div className="d-flex flex-column">
                                            <span className='fw-semibold'>Phone</span>
                                            <span>+251 116 66 00 45/47/48</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center gap-3 text-white">
                                        <span className="fs-2">
                                            <i className="fa-solid fa-envelope"></i>
                                        </span>
                                        <div className="d-flex flex-column">
                                            <span className='fw-semibold'>Email</span>
                                            <span>info@kinophysiotherapy.com</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center gap-3 text-white">
                                        <span className="fs-2">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </span>
                                        <div className="d-flex flex-column">
                                            <span className='fw-semibold'>Address</span>
                                            <span>5<sup>th</sup> floor, Salam Tower, Near Yeka Kifle Ketema Megenagna, Addis Ababa, Ethiopia</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 text-white text-center SmallFont" data-aos="fade-up" data-aos-delay="300">
                <p className="m-0">Design and Developed by AZER Digital Studio</p>
            </div>
        </section>
    </footer>
     );
}
 
export default Footer;