// importing SVG for Navbar
import Home from '../Resources/Images/svg/house-door.svg';
import HomeFull from '../Resources/Images/svg/house-door-fill.svg';
import People from '../Resources/Images/svg/people.svg';
import PeopleFill from '../Resources/Images/svg/people-fill.svg';
import Pain from '../Resources/Images/svg/pain.svg';
import PainFill from '../Resources/Images/svg/pain-fill.svg';
import Health from '../Resources/Images/svg/health.svg';
import Chat from '../Resources/Images/svg/chat-left-dots.svg';
import ChatFill from '../Resources/Images/svg/chat-left-dots-fill.svg';
import HealthFill from '../Resources/Images/svg/health-fill.svg';
import Archieve from '../Resources/Images/svg/archive.svg';
import ArchieveFill from '../Resources/Images/svg/archive-fill.svg';
import Blog from '../Resources/Images/svg/blog.png';
import BlogFill from '../Resources/Images/svg/blog-fill.png';
import Headset from '../Resources/Images/svg/headset.svg';
import HeadsetFill from '../Resources/Images/svg/headset-fill.svg';

// For link 
import { Link } from 'react-router-dom';

// Importing Css file
import '../assets/css/index.css';

//For Animation
import {    useEffect   } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Navbar = () => {
    //-------
    useEffect(()=>{
        AOS.init({
            // Global settings:
            offset: 0, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 600, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: true, // whether animation should happen only once - while scrolling down
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
          })
    },[]);

    return ( 
        <header id="header">
            <nav className="navbar navbar-expand-md">
                <div className="container-fluid">

                    <div className="d-flex align-items-center" data-aos="fade-right">
                            <Link to="/" className="navbar-brand m-0 textColor fw-bold brand"> <span className='LightDark_Green'>Kino Physiotherapy </span><span className="text-secondary fw-normal fst-italic fs-6"><sub>Heal first...</sub></span></Link>
                            <div id="verticalbar" className="mx-xxl-2 mx-1 d-none d-lg-inline-block"> </div>
                            <Link to="/ContactUs" className="nav-link textColor fw-normal lh-sm brandSubHeading d-none d-lg-inline-block fw-normal LightDark_Green SmallFont">Work Hours
                            <br/>
                                <span className="brandSubHeading d-none d-lg-inline-block text-muted SmallFont">Mon-Sat : 8:00 AM - 6:00 PM</span>
                            </Link>
                    </div>


                <div className="justify-content-end align-center" id="main-nav">
                    <div className="d-flex align-self-end TopNav" data-aos="fade-left">
                            <div className="d-none d-lg-block">
                                <ul className="d-flex m-0 list-unstyled fs-7"> {/** the fs-7 is customly built */}
                                    <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to="/" className="nav-link text-center  p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="one iconHeight mx-2" src={Home} alt="Home"/>
                                        <img className="two iconHeight mx-2 FillKinoGreen" src={HomeFull}
                                            alt="Home-fill"/>
                                        </div>
                                        <div className="smallFont textColor">Home</div>
                                    </Link>
                                    </li>
                                    <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to="/About" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={People} alt="About us"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={PeopleFill} alt="About us"/>
                                        </div>
                                        <div className="smallFont textColor">About us</div>
                                    </Link>
                                    </li>
                                    <li className="nav-item iconChange  me-3 pt-2">
                                    <Link to="/Blog" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={Blog} alt="Blog"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={BlogFill} alt="Blog-fill"/>
                                        </div>
                                        <div className="smallFont textColor">Blogs</div>
                                    </Link>
                                    </li>
                                    <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to="/Whathurts" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={Pain} alt="pain" height="20"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={PainFill} alt="pain-fill" />
                                        </div>
                                        <div className="smallFont textColor">What hurts?</div>
                                    </Link>
                                    </li>
                                    <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to="/Treatment" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={Health} alt="Health"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={HealthFill} alt="Health-fill"/>
                                        </div>
                                        <div className="smallFont textColor">Treatments</div>
                                    </Link>
                                    </li>
                                    <li className="nav-item iconChange  me-3 pt-2">
                                    <Link to="/Testimonials" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={Chat} alt="Chat"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={ChatFill} alt="Chat-fill"/>
                                        </div>
                                        <div className="smallFont textColor">Testimonials</div>
                                    </Link>
                                    </li>
                                    <li className="nav-item iconChange  me-3 pt-2">
                                    <Link to="/Resources" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={Archieve} alt="archieve"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={ArchieveFill} alt="archieve-fill"/>
                                        </div>
                                        <div className="smallFont textColor">Resources</div>
                                    </Link>
                                    </li>

                                    <li className="nav-item iconChange  me-3 pt-2">
                                    <Link to="/ContactUs" className="nav-link text-center p-0" >
                                        <div className=" d-flex align-items-center overflow-hidden changeWidth mx-auto">
                                        <img className="iconHeight mx-2" src={Headset} alt="headset"/>
                                        <img className=" iconHeight mx-2 FillKinoGreen" src={HeadsetFill} alt="headset-fill"/>
                                        </div>
                                        <div className="smallFont textColor">Contact us</div>
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                                            
                            {/**Login bottom 
                            <button id="loginBtn" className="btn btn-sm m-sm-2 m-1 bg-primary text-white rounded-4 px-sm-3 px-2 d-flex align-items-center d-none">Login
                                <i className="bi bi-box-arrow-in-right fs-6 text-white ps-1"></i>
                            </button> */}
                            {/* for nav bar drop down 
                            <i className="bi bi-grid d-inline d-lg-none fs-4 p-2"></i> */}
                            
                            <button className="d-inline border-0 d-lg-none" type="button" data-bs-toggle="offcanvas" role="button" data-bs-target="#sidebar">
                                <i className="navbar-toggler-icon togglerIcon"></i>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}
 
export default Navbar;